
/* medication.css */
.btn {
    background-color: white;
}
.hidden {
    display:none;
 }
 .show{
    display:block;
 }
 .meds {
    background-color: white;
 }
 .componentcss{
    height:auto !important;
 }
 .componentcss .nav-fill .nav-item a{
   color:#666666;
}
.componentcss .nav-fill .nav-item:hover{
   color:#666666;
}
 .dx-rg-bs4-table-header-title{
    font-size:14px;
 }
 table > thead > tr > th {
    font-size: 14px;
 }
 table > thead >{
    background-color: #ddd;
 }
 .table tbody tr td {
    padding: 5px;
 }
 .medallergy1 .medallergy .table-responsive.dx-g-bs4-table-container
 {
     height:240px !important;
     width:100% !important;
 }
 
 .scndtbl {
   border-collapse: collapse;
 }
 .scndtbl, th, td {
   border: 1px solid black;
 }

 .scndtbl td {

      height: 50px;
      vertical-align: bottom;
 }
 
 .nav-fill .nav-item {
   flex: none;
   font-size: 12px!important;
   padding: 10px;
   border: 1px solid #ddd;
   letter-spacing: 0.5px;
   background: #f4f4f4;
   color: #666666;
   border-bottom: 0px;
}
#medicationpanel .popup-content 
{
   width:100%!important;
}

.medication-multiselect { height : 150px !important; }