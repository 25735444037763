.add-edit-btn-groups {
    margin: 10px 0 15px 0;
}
.add-edit-btn-groups .btn {
    margin-right: 5px;
}   
thead {
    background-color: #eaeaea;
}
th {
    text-align: left;
}
.dx-rg-bs4-table-header-title {
    font-weight: bold;
}
/*.list-box-border{
    border-bottom: 1px solid #444;
    margin: 20px 0 10px 0;
} */
.table-responsive .dx-g-bs4-table-container {
    border: 1px solid #ddd;
}
.modal-title{
    background-color: transparent;
    float:none;
}
.form-control{
    border: 1px solid #ddd;
}
.table tbody tr td {
    padding: 5px;
}
.input, select{
  padding:5px;
}
/*.modal-footer {
    margin-top: 20px;
}*/
.modal-footer {
    margin-top: 0px !important;
}
/*.table-border{
    border: 1px solid rgb(196, 196, 196);
    padding: 5px;
    margin-bottom: 30px;
}
.table-border2{
    border: 1px solid rgb(196, 196, 196);
    padding: 5px;
}*/
.mylist .calendar tbody>tr:nth-of-type(2n) {
    background-color: #fff;
    color: #333;
}
.modal-footer-bottom-margin {
    margin-bottom: 50px;
}
.add-edit-btn-groups {
    margin: 0;
}
.header-hr{
    border: 1px solid #ddd;
    margin: 5px 0;
}

.modal-title-2{
    font-size: 20px;
}
.maping-textarea{
    height: 200px;
    width: 100%;
}
.checkbox-left{
    float: left;

}
.overflow-body{
    height: 400px;
    overflow: scroll;
}
.left-class{
    float: left;
}
.font-normal{
    font-weight: normal;
}
