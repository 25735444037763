/* visitnotes/womenhistory.css */
.patient-history-content {
  margin: 10px;
}

.past-history-action-button {
  padding: 2px 10px;
  margin-right: 5px;
  margin-top: 4px;
  background-color: #ffffff;
  font-size: 12px;
}
#notesdiv14 .visit-notes-wrapper .add-condition-button {height:auto; }

#notesdiv14 .add-condition { margin-top:10px; }

#notesdiv14 .newpostpopup input[type=checkbox] {
  margin-top: 0px;
}
.Cervicalpopup .modal-body .form-group label {
  margin-top: 0px!important; 
}

.past-history-list {
  font-size: 11px;
}

.past-history-list table {
  width: 100%;
}

.past-history-list table th {
  background-color: #f4f4f4;
  font-weight: 600;
  padding:0px;
  font-size: 12px;
  border-color: #666;
  vertical-align:unset;
  padding: 0px 0px 0px 0px!important; 
}

.gcomment { margin:0px 0px;}
.gcomment label { font-size:14px; color:#666666; padding-bottom:0px;  }
.gcomment textarea { font-size:12px; width:100%; }

.confidtext { float:left; margin-top:12px; }
.confidtext label { float:left; font-weight:normal; margin-top:3px; margin-left:5px; }
.confidtext input { float:left; font-weight:normal; }

.past-history-list table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }


.past-history-list table tr th:nth-child(2) {
 width:172px;
}
.past-history-list table tr th:nth-child(4) {
  width:170px;
}
.past-history-list table tr th:nth-child(4) {
  width:170px;
}

.past-history-list table tr td:nth-child(2) {
  width:169px;
  overflow:hidden;
 }
 .past-history-list table tr td:nth-child(4) {
   width:169px;
   overflow:hidden;
 }
 .past-history-list table tr td:nth-child(4) {
   width:169px;
   overflow:hidden;
 }


.past-history-list table td {
  padding:10px;
  font-size: 14px;
  border-color: #666;
}

.past-history-list-part {
 /* width: 48%; */
  float: left;
  /*margin-left: 10px;*/
}

.past-history-list-part-selector {
  float: left;
  margin-left: 10px;
  padding-top: 2%;
  position: absolute;
  right:-12px;
  top:0px;
}

.past-history-list-part {
  /*overflow-x: scroll;*/
}


.past-history-list-part .table-responsive.dx-g-bs4-table-container
{
    height:330px !important;
    min-height:330px;
}

.past-history-list-part .add-to-diagnosis-checkbox {
    text-align: left;
    margin-top: 10px!important;
  }

.pastbg { background-color: #f4f4f4; float:left; }

.past-history-list-part .popup-content
{width: 40%!important;}

