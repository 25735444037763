/* investigationTable.css */
table tbody tr:nth-of-type(even) {
    background-color: #f4f4f4;
}
/*.observation td{
    background-color: #f4f4f4;
}*/
.pointer {cursor: pointer;}

.selectedBg {
    /*background-color: #337ab7;*/
    background-color: #57b846;
    color: white;
}

.observation .selectedTdBg{
background-color: #dedede !important;;
 

}

.boldd{
    font-weight: 600;
}

#p_wrap, #Investigationdetails {
    white-space: pre-line;
  }
  #Investigationdetails .btn{
    margin:5px;
    -webkit-transition: background-color .5s ease-out;
    -moz-transition: background-color .5s ease-out;
    -o-transition: background-color .5s ease-out;
    transition: background-color .5s ease-out;
    border-width:1px;
    border-color:black;
    font-size:calc(0.65em + .7vmin);
  }
  
  #Investigationdetails .btn:hover{
    color:white;
  }
  #Investigationdetails .btn-o.btn-blue {
    background-color: transparent;
    color: #0275d8;
}
#Investigationdetails .btn-o {
    background-color: transparent;
    border-width: 1px;
}