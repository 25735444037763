/* grid/bgridServer.css */
.box
{
box-shadow: unset !important;
/*margin-top:10px !important;*/
}
.table-responsive.dx-g-bs4-table-container
{
    height:200px !important;
}

.dx-g-bs4-table-sticky {
    position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  border: 1px solid #ddd;;
}
thead{
    background: #f4f4f4;
    border: 1px solid #ddd;
}
.table-bordered {
    border: 1px solid #ddd;
}
.position-relative .dx-g-bs4-header-cell .text-nowrap
{
    border:unset;
}
.table{
    margin-bottom:unset;
}
table>thead>tr>th {
    /*padding: 7px !important;*/
    border: 1px solid #ddd !important;
    font-size: 11px !important;
    cursor: pointer;
    font-weight:normal;
}
.highlightprev
{
    color: red;
}
.selectedBg
{
   
}