/* visitnotes/mylist.css */
.calendar tr td:hover {
    background-color:#57b846;
    color: #ffffff;
   }
   .past-history-wrapper{
       color:black!important;
   }
 
   /*.popup-content .modal-header {
    background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
    background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0!important);
    font-size:16px;
    padding:10px;
    color:#333!important;
  }
*/
.componentcss .mylist .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 3px 5px;
    line-height: 1.42857143;
    vertical-align: middle;
    font-size: 12px;
    border: 1px solid #ddd;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.componentcss .mylist .table-responsive.dx-g-bs4-table-container {
        height: 170px !important; 
}


.componentcss .mylist thead {
    background-color: #eaeaea!important;
}

.componentcss .mylist .table-bordered, thead {
    border: 1px solid #ddd!important;
}

.componentcss .mylist thead {
    background: #f4f4f4!important;
}

/*
#recentvisitlistshort .popup-content {
    width: 50%!important;
}

//Reviewin
#recentvisitlistshort .popup-content {
    width: 400px!important;
}*/

/*Word Fill*/
#recentvisitlistshort .popup-content {
    width: 1000px!important;
}

#recentvisitlistshort .popup-content .mylistbody {
    height: 480px;
    padding:0px 15px!important;
}
#recentvisitlistshort .modal-footer { float:left; width:100%; }

.componentcss .mylist .input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
    z-index: 0;
    float: left;
    margin-top: 0px;
    margin-bottom: 0px;
}
.nav-pills>li+li {
    margin-left: 0px;
}
