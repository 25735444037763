/* inbox.css */
..popup-content {
    width: 99%!important;
}
ul.nav-menu-list-style .nav-header {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #e8e8e8;
    display: block;
    margin: 0;
    line-height: 36px;
    padding: 0px 8px;
    font-size: 15px;
    font-weight: 300;
}
ul.bullets {
    /*list-style: inside disc;*/
    color:#333;
}
.newdesign a{ color:#333333;}

.navpat ul li a{ color:#333333; list-style: inside disc; }

.navpat ul li ul li a{ color:#333333; list-style: inside disc; }

.listinbox
{
    margin-right: 0px;
    font-size: 12px;
    padding: 0px;
    clear: both;
    line-height: 4px;
}
.listbox label {
    margin-bottom: 3px;
}

.listinbox span
{
    margin-left:10px; float:left; margin-top:-2px;     line-height: 18px;
}

.listbox-title {
    font-size: 14px;
    color: #000000;
    padding: 10px 0px 5px 0px;
    font-weight: bold!important;
}
.popup-content .react-datepicker__input-container input
{
    margin-top: 0px!important;
}

ul.nav-menu-list-style> li a {
    border-top: 0px solid #FFFFFF;
    border-bottom: 0px solid #e8e8e8;
}
.chkdet { padding: 2px 10px; margin-top:8px; margin-left:5px; }