/* add-patient-form.css */
.new-patient-wrapper .panel {
  width: 100%;
  background-color: white;
}

.input-group {
  z-index: 0;
}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn-group:not(:last-child)>.btn, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  z-index: 0;
}

.shrink .react-datepicker-ignore-onclickoutside{
  width: 100%;
}

.shrink .react-datepicker__input-container{
  width: 100%;
}

.form-group {
  margin: 0px;
  /* border-right: 1px solid #ddd; */
}

.shrink .react-datepicker__month-container {
  width: 100px;
}

#addPatientForm  label{
	font-size: 12px;
    font-weight: 400;
}
.cus-wid {
  -ms-flex: 0 0 13.666667%!important;
  flex: 0 0 13.666667%!important;
  max-width: 13.666667%!important;
  width: 13.666667%!important;
}
.modal-body label {
  font-size: 12px;
  vertical-align: unset;
}