/* reminder.css */
.commongridclass .table-responsive.dx-g-bs4-table-container
{
    height:230px !important;
}
.popup-content
{width: 50%!important;}

/*.width-adjust{
  width: 50%!important;
}

.popup-content .modal-header {
  background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
  background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0!important);
  font-size:16px;
  padding:10px;
}
.modal-body {
  padding: 0px 5px!important;
  margin: 0px!important;
}

.popup-content .popup-content
{width: 50%!important;}

.popup-content table th {
    background-color: #f4f4f4;
    font-weight: 600;
    padding: 0px;
    font-size: 12px;
    border-color: #666;
    vertical-align: unset;
    padding: 0px 0px 0px 0px!important;
}

.popup-content table th input{ font-size: 12px; font-weight:normal; height:22px!important; border:0px; }
*/
.displayit
{
  display: block;
}
.displayoff
{
  display: none;
}
.react-confirm-alert-body
{
  font-size:16px;
}
.selectedBg {
  /*background-color: #337ab7;*/
  background-color: black!important;
  color: white;
}
.reminder { font-size:14px; border:0px; padding-top:10px!important;  }
.reminder label { margin-right: 12px; }