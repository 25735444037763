/* visitnotes/diagonsis.css */
.section-title {
    margin-top: 0px;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content {
  background: white;
  width: 50rem;
  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  position: relative;
}

.modal-close-btn {
  cursor: pointer;
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
}
/*.patient-history-content .popup-content
{width: 70% !important; padding:0px!important; }

.patient-history-content .modal-header {
  background-image: -webkit-linear-gradient(top,#f5f5f5 0,#e8e8e8 100%);
  background-image: linear-gradient(to bottom,#f5f5f5 0,#e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0!important);
  font-size:16px;
  padding:10px;
}
.patient-history-content .modal-body {
  padding: 0px 5px!important;
  margin: 0px!important;
}*/

.patient-history-content .condition-type {
  display: flex;
  margin: 10px 0px 10px 0px;
  font-weight: 400;
}

.patient-history-content .add-to-my-favourites {
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.patient-history-content .section-title { margin-top:10px;}