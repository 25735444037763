/* epc.css */
.lettertable{
    width: 100%;
}

.lettertable td {
    padding: 10px;
}
.tasktable .table-responsive.dx-g-bs4-table-container {
    height: 335px !important;
    overflow-y: scroll;
    border: 1px solid ddd;
}
.panspace1
{
 background-color: grey !important;
 height: 34px;
 
}