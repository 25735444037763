/* clinical.css */
.clinical-list-part .table-responsive.dx-g-bs4-table-container{ 
    height:430px !important;
}

.table-responsive{
    height: 350px !important;
}

#notesdiv11 .popup-content { width: 40%!important; }

.popup-content .clinicin .react-datepicker__input-container input { margin-top:-20px!important; }