/* addpatienthistory.css */
.datepicker-holder {
  display: flex;
}

.datepicker-holder .today-date {
  width: 150px !important;
}

.datepicker-holder input[type='text'] {
  width: 50px;
  margin-right: 20px;
  border: none;
  border-bottom: 1px solid #cccccc;
}

.past-history-wrapper .datePicker,
.past-history-wrapper .form-control {
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #cccccc;
  margin-top: -20px;
  margin-bottom: 13px;
  padding: 0px;
  /* padding: 0px 12px; */
}


.modal-body {

  padding: 0px;
}


.react-datepicker-wrapper {
  float: left
}

.datePicker {
  margin-top: -14px;

}

.checkbox1 {
  margin-right: -10px;
  margin-top: -14px;
  float: right;
}