/* Calendar.css */
.calendar  tbody>tr:nth-of-type(even) {
      background-color: #eaf8e6;
}

/* .calendar td:first-child {background: #dddddd85} */
/*.calendar td:nth-child(2n+3) {background: #CCC}*/
.calendar th{background: #44514E;
    font-family: "Roboto",sans-serif,Montserrat-Medium;
    font-size: 11px;
    color: #fff!important;
    line-height: 1.4;
    text-transform: uppercase;
    /* box-shadow: 9px 1px 10px 1px #888888; */
    /* box-shadow: -3px 3px 10px 1px #888888; */
    /* box-shadow: -1px 0px 10px 1px #888888; */
}
.table tbody tr td {
    padding: 2px 0px 0px 10px;
    border: 1px solid #dddddd;
}

.box-header .with-border
{
        height: 40px;
    margin-top: 0px;

    top: -14px;
}
.btn-primary{
     background-color: #333333;
     color: #ffffff;
     border: 0px;
     font-size: 12px;
}
.calendarDatePicker{
    width: 10px;


}
.calendarDatePicker:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f073";
    color: #555;
    padding: 0 5px;
}


td .hiddenMore{
    display: none;
}
.ColumnContainer {
    width:110px;
    height: 12px;
    overflow: visible;
}
td  .ColumnContainer:hover .hiddenMore{
        background-color: #ffffff;
        top: 0px;
        left: 0px;
        display : block;
        width: 250px;
        min-height: 100px;
        margin-top: 10px;
        margin-left: -10px;

        height: 100px; 
        border: 1px solid #000000;
        /* z-index: 999;   */
        position:relative; 
}
td .ColumnContainer:hover .ok{
    display : none;
}

td .ColumnContainer .hiddenMore .calendar {
    width: 100%;
}
.highlight_starthere {
    border-top: 4px solid #000;
}
 
  
  /*This will work on every browser but Chrome Browser*/
  .calendar thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    /*z-index: 1;*/
    background-color: antiquewhite;
      color: rgb(0, 0, 0);
    
  }
  
  /*This will work on every browser*/
  .calendar thead th {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      /*z-index: 99;*/
      /*background-color: antiquewhite;*/
      /* background-color: #ffffff; */
      color: rgb(0, 0, 0);
  }

  .popup-overlay {
    position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; background: rgba(0, 0, 0, 0.5); display: flex; z-index: 99999999;
  }

  .blacktext{
      color: #000;
  }

    .react-datepicker__month-container
    {
        z-index: 999999999;
    }


    .standard_popup, .popup-content {
        -webkit-box-shadow: -9px -5px 15px -6px rgba(0,0,0,0.75);
-moz-box-shadow: -9px -5px 15px -6px rgba(0,0,0,0.75);
box-shadow: -9px -5px 15px -6px rgba(0,0,0,0.75);
    }
    .standard_popup, .popup-overlay {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(218, 210, 210, 0.85);
    display: flex;
    z-index: 999;
}
.modal-header {
    background-color: #44514E!important;
    color: #ffffff!important;
    padding: 8px 10px;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
}

    .modal-title {
        background-color: rgb(221, 221, 221);
        float: left;
        width: 100%;
        font-size: 25px;
        margin-bottom: 3px;
        padding: 5px;
    }

    .modal-close{
        margin-top: -34px;
    margin-right: 10px;
    font-size: 25px;
    }
    .seachbtn {
        height: 48px!important;
        right: 5px;
        top: -6px;
        border: 0px;
        background-color: #1ebea5!important;
        color: #ffffff!important;
        padding: 12px 22px;
        font-size: 14px;
        font-weight: bold;
    }
 /*   #addPatientForm { padding:20px;}
    #addPatientForm select,
    textarea, input[type="text"], 
    input[type="password"], 
    input[type="datetime"], 
    input[type="datetime-local"], 
    input[type="date"], input[type="month"], 
    input[type="time"], input[type="week"], 
    input[type="number"], input[type="email"], 
    input[type="url"], input[type="search"], 
    input[type="tel"], input[type="color"], 
    .uneditable-input {
     margin-top:10px!important; margin-bottom:0px!important;
    }*/
    #addPatientForm label{ margin-top:15px!important; }
   
    #addPatientForm .form-control { margin-top:0px; }

    #addPatientForm .react-datepicker-wrapper { margin-top:10px!important; }